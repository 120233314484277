import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { animated, useTransition } from 'react-spring';

const translations = {
  eng: {
    title: 'Choose Your Subscription Plan',
    thirtyDay: '30 DAYS',
    freeTrial: 'FREE TRIAL',
    trial: 'TRIAL',
    featureA: 'Complete Audio Book',
    featureB: 'Quizes & Games',
    featureC: 'Mobile App',
    signup: 'Sign up',
    lifetime: 'LIFETIME',
    subscription: 'SUBSCRIPTION',
  },
  esp: {
    title: 'Elija su plan de suscripción',
    thirtyDay: '30 DIAS',
    freeTrial: 'PRUEBA GRATIS',
    trial: 'PRUEBA',
    featureA: 'Libro de audio completo',
    featureB: 'Pruebas y juegos',
    featureC: 'Aplicación movil',
    signup: 'Regístrate',
    lifetime: 'TODA LA VIDA',
    subscription: 'SUSCRIPCIÓN',
  },
};

const RegisterPage = (props) => {
  const { language } = useSelector((state) => state);
  const [toggle, setToggle] = useState([1, 2, 3]);
  const transitions = useTransition(toggle, null, {
    from: { transform: 'translate3d(1000px, 0,0)' },
    enter: { transform: 'translate3d(0,0,0)' },
    leave: { transform: 'translate3d(-1000px, 0,0)' },
  });

  return (
    <div className="h-100 bg-grey">
      <div className="bg-register">
        <animated.div className="register-inner" style={transitions[0].props}>
          <div>
            <p className="fnt-jomolhari fnt-r-38 fnt-black">{translations[language].title} </p>
          </div>
          <div className="plan-container">
            <div className="plan-item">
              <div className="plan-item-top">
                <div className="plan-item-a">
                  <p className="fnt-primary bold fnt-poppins fnt-r-22 mb-0">{translations[language].thirtyDay}</p>
                  <p className="free-trial-text bold fnt-poppins fnt-r-22 mb-0">{translations[language].freeTrial}</p>
                </div>
                <div className="plan-item-b">
                  <p className="price-text m-0 fnt-poppins bold">0</p>
                  <p className="free-trial-text fnt-r-16 fnt-poppins ">{translations[language].trial}</p>
                </div>
              </div>
              <div className="plan-item-bottom">
                <div className="dsp-flx flx-center flx-col">
                  <p className="fnt-poppins free-trial-text features-text fnt-r-16 fnt-weight-600">
                    {translations[language].featureA}
                  </p>
                  <p className="fnt-poppins free-trial-text features-text fnt-r-16 fnt-weight-600">
                    {translations[language].featureB}
                  </p>
                  <p className="fnt-poppins free-trial-text features-text fnt-r-16 fnt-weight-600">
                    {translations[language].featureC}
                  </p>
                </div>
                <div className="dsp-flx flx-center">
                  <Link
                    to={'/register?level=1'}
                    className="register-btn bg-prime fnt-evolventa fnt-white fnt-14 bold border-none pr-4 pl-4 pt-3 pb-3"
                  >
                    {translations[language].signup}
                  </Link>
                </div>
              </div>
            </div>
            <div className="plan-item">
              <div className="plan-item-top">
                <div className="plan-item-a">
                  <p className="fnt-primary bold fnt-poppins fnt-r-22 mb-0">{translations[language].lifetime}</p>
                  <p className="free-trial-text bold fnt-poppins fnt-r-22 mb-0">
                    {translations[language].subscription}
                  </p>
                </div>
                <div className="plan-item-b">
                  <p className="price-text standard-price m-0 fnt-poppins bold">39</p>
                  <p className="free-trial-text fnt-r-16 fnt-poppins ">{translations[language].lifetime}</p>
                </div>
              </div>
              <div className="plan-item-bottom">
                <div className="dsp-flx flx-center flx-col">
                  <p className="fnt-poppins free-trial-text features-text fnt-r-16 fnt-weight-600">
                    {translations[language].featureA}
                  </p>
                  <p className="fnt-poppins free-trial-text features-text fnt-r-16 fnt-weight-600">
                    {translations[language].featureB}
                  </p>
                  <p className="fnt-poppins free-trial-text features-text fnt-r-16 fnt-weight-600">
                    {translations[language].featureC}
                  </p>
                </div>
                <div className="dsp-flx flx-center">
                  <Link
                    to={'/register?level=2'}
                    className="register-btn bg-prime fnt-evolventa fnt-white fnt-14 bold border-none pr-4 pl-4 pt-3 pb-3"
                  >
                    {translations[language].signup}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </animated.div>
      </div>
    </div>
  );
};

export default RegisterPage;
